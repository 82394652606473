<template>
  <div class="cardset">
    <TopBar title="卡片设置">
      <BackIcon></BackIcon>
    </TopBar>
    <v-container class="px-5">
      <TopInfo :title="title" :img="imgPath"></TopInfo>
      <div class="text-subtitle-1 grey--text">选择主页卡片是否展开</div>
      <div v-for="(setting, i) in settings" :key="`${i}-setting`">
        <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>
        <v-list-item :key="`${i}-${setting.text}`" class="px-0">
          <v-list-item-content>
            <v-list-item-title v-text="setting.text"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <el-switch v-model="setting.show"></el-switch>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-container>
  </div>
</template>

<script>
import { setChildViewMixin } from 'common/mixin'
import set from 'assets/img/set.svg'
import setDark from 'assets/img/setDark.svg'
import { mapState } from 'vuex'
export default {
  name: 'CardSet',
  mixins: [setChildViewMixin],
  data() {
    return {
      title: '卡片设置',
    }
  },
  // 组件销毁时保存setting到Vuex管理
  destroyed() {
    this.$store.commit('save_setting', this.settings)
  },
  computed: {
    ...mapState({
      settings: state => state.settings
    }),
    imgPath() {
      return this.isDark ? setDark : set
    }
  }
}
</script>

<style scoped>

</style>